import clickOutside from '@/js/directives/click-ouside'
import iframeResize from '@/js/directives/iframe-resize'

const GlobalDirectives = {
  install(app) {
    app.directive('click-outside', clickOutside)
    app.directive('resize', iframeResize)
  },
}

export default GlobalDirectives
