import iFrameResize from 'iframe-resizer/js/iframeResizer'

export default {
  bind: function (el, { value = {} }) {
    el.addEventListener('load', () => iFrameResize(value, el))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners()
  },
}
